import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { get, isEmpty } from "lodash";
import logger from "../../common/utilities/logger/index.js";
import getProspects from "../../services/rest/getProspects/index.js";
import Error from "../error";
import getCustomerExists from "../../services/rest/getCustomerExists";
import appConstant from "common/constants/appConstant";
import ReRateCartApi from "services/rest/selectPlanApi";
import { setCartDetails, setCustomerExists, setProspectDetails, showLoader } from "redux/actions";
import ROUTE_CONSTANTS from "common/constants/route";
import ProspectDetails from "components/prospectDetails";
import { isMobile } from "common/utilities/ generic.js";
import moment from "moment";

const RetrieveQuote = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const { prospectData, sdkConfig, cmsData } = useSelector((state) => state.userDetails);

    const [isError, setError] = useState(false);
    const [isApiError, setIsApiError] = useState(false);
    const [isComponentRefresh, setIsComponentRefresh] = useState(false);

    const { prospectId } = sdkConfig;

    const { prospect_loader, prospect_loader_mobile } = cmsData;

    useEffect(() => {
        const getInitialData = async () => {
            setShowLoader();

            await getProspectDataByProspectId();
        };

        getInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderComponent = () => {
        if (prospectData && prospectData.details && prospectData.pets && prospectData.cart) {
            return (
                <ProspectDetails
                    getCustomer={getCustomer}
                    getCartDetails={getCartDetails}
                    setError={setError}
                    isError={isError}
                    isApiError={isApiError}
                    setIsApiError={setIsApiError}
                    refreshComponent={refreshComponent}
                />
            );
        } else {
            logger.info("no data found in get Prospect API", prospectData);

            return <Error />;
        }
    };

    const refreshComponent = () => {
        setIsComponentRefresh(!isComponentRefresh);
    };

    const getProspectDataByProspectId = async () => {
        try {
            logger.info("Initiating prospect API call");

            const { payload } = await getProspects.invoke(prospectId);
            const prospectData = get(payload, "payload.responses[0]", "");

            dispatch(setProspectDetails(prospectData));

            logger.info("prospect API call successfull");

            return prospectData;
        } catch (error) {
            logger.error("error in get Prospect API:", error);

            history.push(ROUTE_CONSTANTS.ERROR);

            return false;
        }
    };

    const getCustomer = async () => {
        try {
            const { email } = prospectData.details[0];

            const preparePayload = {
                q: email,
                type: "email",
            };

            const { payload } = await getCustomerExists.invoke(preparePayload);

            logger.info("get Customer Exists API call successfull");

            dispatch(setCustomerExists(payload?.payload?.response));

            const isCustomerExists = get(payload, "payload.response.isExist", false);

            return isCustomerExists;
        } catch (error) {
            logger.error("Error in Customer Exists API:", error);

            history.push(ROUTE_CONSTANTS.ERROR);

            return false;
        }
    };

    const getCartDetails = async (userEmail, zipcode) => {
        try {
            const postData = {
                customer: {
                    email: userEmail,
                    zipcode,
                    skipRerate: true,
                },
                currentDateTime: moment().format(appConstant.ISO_DATE),
                applicationIdentifier: appConstant.SOURCE.FETCH_BUY,
            };

            logger.info("Cart API payload:", JSON.stringify(postData));

            const fetchReRateCart = new ReRateCartApi();

            const { payload: fetchReRateCartData } = await fetchReRateCart.invoke(postData);

            logger.info("get cart API call successfull");

            const getCustomerDetails = get(fetchReRateCartData, "payload.response.cartResponse.customer", []);
            const getCustomerQuote = getCustomerDetails?.pets.filter((pet) => pet.quotes.length > 0);

            const filterCartLineIdFromCart = getCustomerQuote.map((item) => {
                return {
                    cartLineItemId: item.cartLineItemId,
                    petName: item.petName,
                    id: item.id,
                    effectiveDate: item?.quotes[0]?.effectiveDate,
                    effectiveDateTime: item?.quotes[0]?.effectiveDateTime,
                    petModelLawConfig: getCustomerDetails?.petModelLawConfig,
                };
            });

            logger.info("Cart API response:", filterCartLineIdFromCart);

            dispatch(setCartDetails(fetchReRateCartData));

            return fetchReRateCartData;
        } catch (error) {
            logger.error("Error in get cart API:", error);

            history.push(ROUTE_CONSTANTS.ERROR);

            return error;
        }
    };

    const setShowLoader = () => {
        let Loader = prospect_loader;

        if (isMobile()) {
            Loader = prospect_loader_mobile;
        }

        dispatch(showLoader({ isLoading: true, loaderMetadata: { loaderAnimation: Loader } }));
    };

    return (
        <>
            {isComponentRefresh && (
                <div className="payment-container">{!isEmpty(prospectData) && renderComponent()}</div>
            )}
            {!isComponentRefresh && (
                <div className="payment-container">{!isEmpty(prospectData) && renderComponent()}</div>
            )}
        </>
    );
};

export default RetrieveQuote;

RetrieveQuote.propTypes = {};
