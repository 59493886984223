import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import logger from "common/utilities/logger";
import { RichText } from "prismic-reactjs";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { hideLoader, setConfirmIntentData, setConfirmScreenData, setCustomerDetails, showLoader } from "redux/actions";
import { CheckBox, TextInput } from "components/common/FormControls";
import { Button, DialogContent } from "@material-ui/core";
import appConstant from "common/constants/appConstant";
import { isMobile } from "common/utilities/ generic";
import ROUTE_CONSTANTS from "common/constants/route";
import {
    htmlSerializer,
    prepareConfirmIntentPayload,
    prepareQuoteToPolicyPayload,
    prepareStripePayload,
} from "./helper";
import createCustomerApi from "services/rest/createCustomerApi";
import ConfirmIntentApi from "services/rest/confirmIntentApi";
import { sendPostMessage } from "common/utilities/eventUtil";
import createPolicy from "services/rest/createPolicy";
import { get } from "lodash";
import ModalPanel from "components/common/Panel/modal/modal";
import pageLoadConfig from "common/utilities/analytics/config/mappings/pageLoadMappings";
import { sendAnalyticsOnEvent, sendAnalyticsOnLoad } from "common/utilities/analytics";
import {
    getErrorInputFieldMapping,
    getFieldMappingForPayment,
    getInternalLinkMapping,
    getTosClickedMapping,
} from "common/utilities/analytics/config/mappings/eventDataLayerMappings";

const PaymentCardDetails = ({
    prospectId,
    setError,
    isError,
    isApiError,
    setIsApiError,
    refreshComponent,
    paymentType,
    changePaymentType,
}) => {
    const initialFormData = {
        firstName: "",
        lastName: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zipcode: "",
        billingAddress1: "",
        billingAddress2: "",
        billingCity: "",
        billingCountry: "",
        billingState: "",
        billingZipCode: "",
        mobNumber: "",
        cardName: "",
        acknowledgeTermsOfSale: false,
    };

    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    let history = useHistory();

    const { cmsData, cartData, existingCustomer, customerData, confirmIntentData, sdkConfig } = useSelector(
        (state) => state.userDetails
    );
    const { currentTheme } = useSelector((state) => state.session);
    const { showBankAccount = false, showConfirmationScreen = false } = sdkConfig;

    const cartDetails = get(cartData, "payload.response.cartResponse", {});
    const petModelLawApplicable = get(cartDetails, "customer.petModelLawConfig.petModelLawApplicable", false);
    const country = get(cartDetails, "customer.details[0].country", "");
    const state = get(cartDetails, "customer.pets[0].state", "");

    const quotes = cartDetails?.customer?.pets.filter((item) => item.quotes.length > 0);
    const rfYearMap = quotes?.map((item) => item?.rateMatrix[0]?.rateFilingYear) || [];
    const rfYear = Math.max(...rfYearMap);
    const petEnrollmentFeeLength = quotes?.filter((pet) => pet.quotes[0].enrollmentFee > 0);

    const {
        fetch_logo,
        fetch_branding,
        card_details_heading,
        heading,
        sub_heading,
        name_on_bank,
        name_on_card,
        accept_message,
        term_of_sales,
        button_name,
        lock_icon,
        secured_message,
        stripe_icon,
        terms_and_conditions,
        terms_and_conditions_url,
        privacy_policy,
        privacy_policy_url,
        payment_error,
        payment_error_heading,
        payment_error_content,
        payment_loader,
        payment_loader_mobile,
        tos_heading,
        tos_description,
        tos_description_can,
        tos_close,
        tos_description_pml,
        tos_description_can_pml,
        tos_fraud_notice,
        tos_fraud_notice_can_pml,
        tos_description_2024_pml,
        tos_description_admin_2024_pml,
        tos_description_2024_non_pml,
        tos_description_admin_2024_non_pml,
    } = cmsData;

    const [formData, setFormData] = useState(initialFormData);
    const [isButtonDisable, setIsButtonDisable] = useState(true);
    const [fieldError, setFieldError] = useState({});
    const [setupIntentValue, saveSetupIntentValue] = useState();
    const [isModalOpen, setModalOpen] = useState(false);

    const paymentOption = {
        defaultValues: {
            name: formData.cardName,
            email: formData.email,
            billingDetails: {
                address: {
                    country: formData.billingCountry,
                    postal_code: formData.billingZipCode,
                },
            },
        },
        paymentMethodOrder: [appConstant.PAYMENT_METHOD.US_BANK, appConstant.PAYMENT_METHOD.CARD],
        fields: {
            billingDetails: {
                name: "never",
                email: "never",
            },
        },
        terms: {
            card: "never",
            usBankAccount: "never",
        },
    };

    const setShowLoader = () => {
        let Loader = payment_loader;

        if (isMobile()) {
            Loader = payment_loader_mobile;
        }

        dispatch(showLoader({ isLoading: true, loaderMetadata: { loaderAnimation: Loader } }));
    };

    const setHideLoader = () => {
        dispatch(hideLoader({ isLoading: false }));
    };

    const handleOnChange = (e, value) => {
        setFormData({
            ...formData,
            [value.name]: value.value,
        });

        if (value.name === "acknowledgeTermsOfSale") {
            sendAnalyticsOnInputFieldError(value.name, value.value);
        }
    };

    const handleOnBlur = (e) => {
        const targetName = e.target.name;

        const copyFieldError = { ...fieldError };

        if (e.target.value.trim() === "") {
            copyFieldError[targetName] = true;

            sendAnalyticsOnInputFieldError(targetName, false);
        } else {
            delete copyFieldError[targetName];

            sendAnalyticsOnInputFieldError(targetName, true);
        }

        setFieldError(copyFieldError);
    };

    const onPaymentChange = (event) => {
        changePaymentType(event);

        setError(false);

        if (event?.complete) {
            setIsApiError(false);
        }
    };

    const onPaymentReady = () => {
        setHideLoader();
    };

    const checkFormInvalid = () => {
        const validateForm = { ...formData };
        delete validateForm.address2;
        delete validateForm.billingAddress2;

        return Object.values(validateForm).some((v) => v === null || v.toString().trim() === "" || v === false);
    };

    const checkCardDetailsInvalid = () => {
        if (paymentType?.complete) {
            return false;
        }
        return true;
    };

    const validateNewCustomerForm = () => {
        const isFormDataInvalid = checkFormInvalid();

        const isCardDetailsInvalid = checkCardDetailsInvalid();

        return !isFormDataInvalid && !isCardDetailsInvalid;
    };

    useEffect(() => {
        const getCustomerDetails = cartDetails && cartDetails.customer;

        if (getCustomerDetails) {
            const {
                firstName = "",
                lastName = "",
                email,
                contacts = [],
                addresses = [],
            } = getCustomerDetails.details[0];

            const mobileNo = contacts.find(({ type }) => type === appConstant.CONTACTS_TYPE.MOBILE);
            const address = addresses.find(({ type }) => type === "MAILING");
            const billingAddress = addresses.find(({ type }) => type === "BILLING") || address;

            let updateFormData = { ...formData };

            updateFormData = {
                ...formData,
                firstName,
                lastName,
                email,
                mobNumber: mobileNo?.value || "",
                cardName: `${firstName} ${lastName}`.trim(),
                address1: address?.addressLine1,
                address2: address?.addressLine2,
                city: address?.city,
                state: address?.state,
                zipcode: address?.zipcode,
                country:
                    address.country === appConstant.COUNTRY_CODE_CANADA ? appConstant.COUNTRY_CODE_CA : address.country,
                billingAddress1: billingAddress?.addressLine1,
                billingAddress2: billingAddress?.addressLine2,
                billingCity: billingAddress?.city,
                billingState: billingAddress?.state,
                billingZipCode: billingAddress?.zipcode,
                billingCountry:
                    billingAddress.country === appConstant.COUNTRY_CODE_CANADA
                        ? appConstant.COUNTRY_CODE_CA
                        : billingAddress.country,
            };

            setFormData(updateFormData);

            sendAnalyticsOnLoad(pageLoadConfig.purchaseWidget, get(getCustomerDetails, "customerId", ""), email);

            logger.info("Rendered Payment-card component successfully", updateFormData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartData]);

    useEffect(() => {
        const getValidators = () => [validateNewCustomerForm];

        const validators = getValidators();

        const isDisabled = validators.some((validator) => validator.call());

        setIsButtonDisable(!isDisabled);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isButtonDisable, formData, paymentType]);

    useEffect(() => {
        if (customerData && customerData.length && customerData[0].platformCustomerUserId) {
            confirmIntent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData]);

    useEffect(() => {
        if (confirmIntentData && confirmIntentData.walletId) {
            quoteToPolicy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmIntentData]);

    const submitPayment = async () => {
        setError(false);
        setIsApiError(false);

        logger.info("Validating form, card details & preparing for stripe confirm Setup API");

        const isFormDataInvalid = checkFormInvalid();
        const isCardDetailsInvalid = checkCardDetailsInvalid();

        if (!isFormDataInvalid && !isCardDetailsInvalid) {
            setShowLoader();

            logger.info(`Initiating Stripe confirm Setup API for ${paymentType?.value?.type}`);

            const setupIntent = await confirmCardSetup();

            if (setupIntent) {
                saveSetupIntentValue(setupIntent);

                createCustomer(setupIntent);
            } else {
                setError(true);

                sendAnalyticsOnSubmitPayment("Failure");

                setHideLoader();
            }
        } else {
            logger.info(
                `Form data OR Card details are INVALID: Form Data: ${isFormDataInvalid} && Card Details: ${isCardDetailsInvalid}}`
            );

            setError(true);

            setHideLoader();
        }
    };

    const confirmCardSetup = async () => {
        try {
            const cardSetupPayload = prepareStripePayload(formData);

            const { setupIntent, error } = await stripe.confirmSetup({
                elements,
                confirmParams: { ...cardSetupPayload },
                redirect: "if_required",
            });

            if (error) {
                logger.error(`Error in stripe confirm setup API for ${paymentType?.value?.type}`, error);
            }

            return setupIntent;
        } catch (error) {
            logger.error(`Error in stripe confirm setup API for ${paymentType?.value?.type}`, error);

            sendAnalyticsOnSubmitPayment("Failure");

            return error;
        }
    };

    const createCustomer = async (setupIntent) => {
        if (!existingCustomer.isExist) {
            try {
                logger.info("Initiating create customer API");

                const createCustomerPayload = { ...formData };

                const customerPayload = await createCustomerApi.invoke(createCustomerPayload);

                dispatch(setCustomerDetails(customerPayload?.payload?.payload?.response?.customers));
            } catch (error) {
                logger.error("error in create customer API", error);

                sendAnalyticsOnSubmitPayment("Failure");

                history.push(ROUTE_CONSTANTS.ERROR);
            }
        } else {
            confirmIntent(setupIntent);
        }
    };

    const confirmIntent = async (setupIntent = setupIntentValue) => {
        try {
            logger.info(`Initiating confirm intent API with status: ${setupIntent?.status}`);

            const countryCode = formData.country;

            const authToken = `Bearer ${sdkConfig?.bearerToken}`;

            const nameOnCard = formData.cardName.trim();

            const paymentTypes = paymentType?.value?.type;

            const confirmIntentPayload = prepareConfirmIntentPayload(setupIntent, prospectId, countryCode, nameOnCard);

            const confirmIntentApi = new ConfirmIntentApi(
                authToken,
                confirmIntentPayload,
                showBankAccount,
                paymentTypes
            );

            const data = await confirmIntentApi.invoke();

            dispatch(setConfirmIntentData(data?.payload?.payload?.response));
        } catch (error) {
            sendAnalyticsOnSubmitPayment("Failure");

            if (error?.error?.code === 401) {
                logger.error("session expired in confirm intent API call", error);

                setTimeout(() => {
                    sendPostMessage("salesFunnel-sdk-session-expired");
                }, 100);
            } else {
                logger.error("error in confirm intent API", error);

                dispatch(setCustomerDetails({}));

                dispatch(setConfirmIntentData({}));

                history.push(ROUTE_CONSTANTS.ERROR);
            }
        }
    };

    const quoteToPolicy = async () => {
        try {
            logger.info(`Initiating Quote to policy API for ${paymentType?.value?.type}`);

            const platformCustomerUserId = get(
                customerData,
                "[0].platformCustomerUserId",
                existingCustomer?.platformCustomerUserId
            );

            const preparePayload = prepareQuoteToPolicyPayload(
                cartData,
                formData,
                confirmIntentData,
                platformCustomerUserId
            );

            const policyResponse = await createPolicy.invoke(preparePayload);

            const policy = get(policyResponse, "payload.payload.responses", {});

            const successfulPolicyPayments = policy.filter(
                (policy) =>
                    policy.isPaymentSuccessful ||
                    (!policy.isPaymentSuccessful &&
                        policy?.paymentStatus?.code?.toUpperCase() === appConstant.PAYMENT_STATUS.PROCESSING)
            );

            const customerID = get(customerData, "0].externalCustomerdentifier", existingCustomer?.customerExternalRef);

            if (successfulPolicyPayments.length > 0) {
                logger.info(
                    `Quote to policy API call successfull for ${paymentType?.value?.type}`,
                    JSON.stringify(policy)
                );

                logger.info(
                    `A policy has been purchased with a ${paymentType?.value?.type}, and the associated customer ID is`,
                    customerID
                );

                sendAnalyticsOnSubmitPayment("Success");

                if (showConfirmationScreen) {
                    const payload = {
                        paymentMethod: paymentType?.value?.type,
                        customerID,
                        status: setupIntentValue?.status,
                        nextAction: setupIntentValue?.next_action,
                        liveMode: setupIntentValue?.livemode,
                    };

                    dispatch(setConfirmScreenData(payload));

                    history.push(ROUTE_CONSTANTS.CONFIRMATION);
                } else {
                    logger.info("send Post Message with customerID:", customerID);

                    setTimeout(() => {
                        sendPostMessage("salesFunnel-sdk-success", customerID);
                    }, 1000);
                }
            } else {
                logger.error(`Quote to policy API call failed for ${paymentType?.value?.type}`, JSON.stringify(policy));

                logger.info("Policy payment failed, refresh component with error message");

                setIsApiError(true);

                handleQuoteToPolicyError();
            }
        } catch (error) {
            logger.error(`error in Quote to policy API for ${paymentType?.value?.type}`, error);

            setIsApiError(true);

            handleQuoteToPolicyError();
        }
    };

    const handleQuoteToPolicyError = () => {
        dispatch(setCustomerDetails({}));

        dispatch(setConfirmIntentData({}));

        sendAnalyticsOnSubmitPayment("Failure");

        refreshComponent(true);
    };

    const onClick = (prismicUrl) => {
        window.open(prismicUrl, "_blank");

        const mapping = getInternalLinkMapping(prismicUrl);

        sendAnalyticsOnEvent(mapping, false);
    };

    const onDialogOpen = () => {
        setModalOpen(true);

        sendAnalyticsOnModalLoad(true);
    };

    const onDialogClose = () => {
        setModalOpen(false);

        sendAnalyticsOnModalLoad(false);
    };

    const sendAnalyticsOnInputFieldError = (targetName, value) => {
        const mapping = getErrorInputFieldMapping(targetName, value);

        sendAnalyticsOnEvent(mapping, false);
    };

    const sendAnalyticsOnModalLoad = (value) => {
        const mapping = getTosClickedMapping(value);

        sendAnalyticsOnEvent(mapping, false);
    };

    const sendAnalyticsOnSubmitPayment = (value) => {
        const customerDetails = get(cartDetails, "customer", {});

        const mapping = getFieldMappingForPayment(value, customerDetails);

        sendAnalyticsOnEvent(mapping, false);
    };

    const getSamplePolicyPml = () => {
        return get(
            cmsData,
            `view_sample_policy_pml_${state.toLowerCase()}`,
            get(cmsData, "view_sample_policy_pml_else", "")
        );
    };

    const getSamplePolicy2024 = () => {
        if (petModelLawApplicable) {
            return get(
                cmsData,
                `view_sample_policy_pml_2024_${state.toLowerCase()}`,
                get(cmsData, "view_sample_policy_pml_2024_else", "")
            );
        } else {
            return get(
                cmsData,
                `view_sample_policy_non_pml_2024_${state.toLowerCase()}`,
                get(cmsData, "view_sample_policy_non_pml_2024_else", "")
            );
        }
    };

    const getTOS2024 = () => {
        let tos = tos_description_2024_pml;

        if (petModelLawApplicable) {
            tos = petEnrollmentFeeLength.length >= 1 ? tos_description_admin_2024_pml : tos_description_2024_pml;
        } else {
            tos =
                petEnrollmentFeeLength.length >= 1 ? tos_description_admin_2024_non_pml : tos_description_2024_non_pml;
        }

        return tos;
    };

    return (
        <div className="payment-card-details">
            <div className="payment-card-details__grid-container">
                <div className="payment-card-details__grid-container__main">
                    <div className="payment-card-details__grid-container__main__left">
                        <div className="payment-card-details__grid-container__main__left__heading">
                            {showBankAccount && country === appConstant.COUNTRY_CODE_US
                                ? heading
                                : card_details_heading}
                        </div>

                        {showBankAccount && country === appConstant.COUNTRY_CODE_US && (
                            <div className="payment-card-details__grid-container__main__left__sub-heading">
                                {sub_heading}
                            </div>
                        )}

                        {isError && (
                            <div className="payment-card-details__grid-container__main__left__payment-error">
                                <p>{payment_error}</p>
                            </div>
                        )}

                        {isApiError && (
                            <div className="payment-card-details__grid-container__main__left__payment-error">
                                <p>
                                    <b>{payment_error_heading}</b>
                                    {payment_error_content}
                                </p>
                            </div>
                        )}

                        <div className="payment-card-details__grid-container__main__left__card">
                            <TextInput
                                name="cardName"
                                value={formData.cardName}
                                onChange={handleOnChange}
                                identifier="card-holder-name"
                                type="text"
                                inputVariant="filled"
                                inputLabel={
                                    paymentType?.value?.type === appConstant.PAYMENT_METHOD.US_BANK
                                        ? name_on_bank
                                        : name_on_card
                                }
                                error={fieldError["cardName"]}
                                onBlur={handleOnBlur}
                            />

                            <div className="payment-card-details__grid-container__main__left__card-details">
                                <PaymentElement
                                    id="payment-element"
                                    options={paymentOption}
                                    onChange={onPaymentChange}
                                    onReady={onPaymentReady}
                                />
                            </div>
                        </div>

                        <div
                            className={`payment-card-details__grid-container__main__left__checkbox-accept ${
                                paymentType?.value?.type === appConstant.PAYMENT_METHOD.US_BANK
                                    ? `payment-card-details__grid-container__main__left__border-top ${
                                          paymentType?.complete &&
                                          "payment-card-details__grid-container__main__left__margin-top"
                                      }`
                                    : ""
                            }`}
                        >
                            <CheckBox
                                name="acknowledgeTermsOfSale"
                                identifier="acknowledgeTermsOfSale"
                                onChange={handleOnChange}
                                color="default"
                                value={formData.acknowledgeTermsOfSale}
                            />
                            <div>
                                <span className="accept-msg">{accept_message}</span>
                                <span className="term-of-sales">
                                    {/* eslint-disable-next-line */}
                                    <a href="#" onClick={() => onDialogOpen()}>{`${term_of_sales}`}</a>
                                </span>
                            </div>
                        </div>

                        <div className="payment-card-details__grid-container__main__left__button">
                            <Button
                                type="button"
                                size="large"
                                onClick={submitPayment}
                                fullWidth
                                variant="contained"
                                className="submit-button"
                                id="submit"
                                disabled={isButtonDisable}
                            >
                                {button_name}
                            </Button>
                        </div>
                    </div>

                    <div className="payment-card-details__grid-container__main__right">
                        <div className="payment-card-details__grid-container__main__right__logo">
                            <img src={fetch_logo.url} alt={fetch_logo.alt} />
                        </div>
                    </div>
                </div>

                <div className="payment-card-details__grid-container__footer">
                    <div className="payment-card-details__grid-container__footer__left">
                        <img src={lock_icon.url} alt={lock_icon.alt} className="lock-icon" />
                        <span className="secured_message">{secured_message}</span>
                        <img src={stripe_icon.url} alt={stripe_icon.alt} />
                    </div>
                    <div
                        className="payment-card-details__grid-container__footer__fetch-branding"
                        dangerouslySetInnerHTML={{
                            __html: fetch_branding,
                        }}
                    />

                    <div className="payment-card-details__grid-container__footer__right">
                        <span>
                            {/* eslint-disable-next-line */}
                            <a href="#" onClick={() => onClick(terms_and_conditions_url)}>
                                {terms_and_conditions}
                            </a>
                        </span>
                        <span className="content-line">{`|`}</span>
                        <span>
                            {/* eslint-disable-next-line */}
                            <a href="#" onClick={() => onClick(privacy_policy_url)}>
                                {privacy_policy}
                            </a>
                        </span>
                    </div>
                </div>
            </div>

            <ModalPanel identifier={`tos-modal`} showDialog={isModalOpen} onClose={() => onDialogClose()}>
                <DialogContent>
                    <div className={`theme-${currentTheme.toLowerCase()}`}>
                        {!petModelLawApplicable && rfYear < 2024 && (
                            <>
                                <div className="tos-modal-heading">{tos_heading}</div>
                                <div className="tos-modal-content">
                                    <RichText
                                        render={
                                            country === appConstant.COUNTRY_CODE_US
                                                ? tos_description
                                                : tos_description_can
                                        }
                                        htmlSerializer={htmlSerializer}
                                    />
                                </div>
                                <div className="tos-modal-close">
                                    {/* eslint-disable-next-line */}
                                    <a href="#" onClick={() => onDialogClose()}>
                                        {tos_close}
                                    </a>
                                </div>
                            </>
                        )}

                        {petModelLawApplicable && rfYear < 2024 && (
                            <>
                                <div className="tos-modal-heading">{tos_heading}</div>
                                <div className="tos-modal-content">
                                    <RichText
                                        render={
                                            country === appConstant.COUNTRY_CODE_US
                                                ? tos_description_pml
                                                : tos_description_can_pml
                                        }
                                        htmlSerializer={htmlSerializer}
                                    />
                                    <RichText render={getSamplePolicyPml()} htmlSerializer={htmlSerializer} />
                                    <RichText
                                        render={
                                            country === appConstant.COUNTRY_CODE_US
                                                ? tos_fraud_notice
                                                : tos_fraud_notice_can_pml
                                        }
                                        htmlSerializer={htmlSerializer}
                                    />
                                </div>
                                <div className="tos-modal-close">
                                    {/* eslint-disable-next-line */}
                                    <a href="#" onClick={() => onDialogClose()}>
                                        {tos_close}
                                    </a>
                                </div>
                            </>
                        )}

                        {rfYear >= 2024 && (
                            <>
                                <div className="tos-modal-heading">{tos_heading}</div>
                                <div className="tos-modal-content">
                                    <RichText render={getTOS2024()} htmlSerializer={htmlSerializer} />
                                    <RichText render={getSamplePolicy2024()} htmlSerializer={htmlSerializer} />
                                    <RichText
                                        render={
                                            country === appConstant.COUNTRY_CODE_US
                                                ? tos_fraud_notice
                                                : tos_fraud_notice_can_pml
                                        }
                                        htmlSerializer={htmlSerializer}
                                    />
                                </div>
                                <div className="tos-modal-close">
                                    {/* eslint-disable-next-line */}
                                    <a href="#" onClick={() => onDialogClose()}>
                                        {tos_close}
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </DialogContent>
            </ModalPanel>
        </div>
    );
};

export default PaymentCardDetails;

PaymentCardDetails.propTypes = {
    prospectId: PropTypes.string,
    setError: PropTypes.func,
    isError: PropTypes.bool,
    setIsApiError: PropTypes.func,
    isApiError: PropTypes.bool,
    refreshComponent: PropTypes.func,
    paymentType: PropTypes.object,
    changePaymentType: PropTypes.func,
};
