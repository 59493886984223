import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";
import SetupIntentApi from "services/rest/SetupIntentApi";
import { get } from "lodash";
import PaymentCardDetails from "components/paymentCardDetails";
import ROUTE_CONSTANTS from "common/constants/route";
import logger from "common/utilities/logger";
import { sendPostMessage } from "common/utilities/eventUtil";
import { useSelector } from "react-redux";
import appConstant from "common/constants/appConstant";

const PaymentDetails = ({
    setPaymentKeys,
    paymentKeys,
    authorizationSetUpIntent,
    countryCode,
    prospectId,
    setError,
    isError,
    isApiError,
    setIsApiError,
    refreshComponent,
    paymentType,
    changePaymentType,
}) => {
    let history = useHistory();
    const { sdkConfig } = useSelector((state) => state.userDetails);
    const { showBankAccount = false } = sdkConfig;

    const setupIntent = new SetupIntentApi(authorizationSetUpIntent, prospectId, countryCode, showBankAccount);

    const appearance = {
        theme: "stripe",
        variables: {
            colorPrimary: "#5757F2",
            colorTextSecondary: "#515151",
            colorBackground: "#ffffff",
            colorText: "#515151",
            borderRadius: "16px",
            fontFamily: `"DM Sans", sans-serif`,
        },
        rules: {
            ".Label": {
                color: "#515151",
                fontSize: "16px",
                fontWeight: "400",
            },
            ".Label--floating": {
                color: "#515151",
                fontSize: "13px",
                fontWeight: "400",
                opacity: "1",
            },
            ...(paymentType?.value?.type === appConstant.PAYMENT_METHOD.CARD && {
                ".Input": {
                    lineHeight: "23px",
                    fontSize: "16px",
                    border: "1px solid #C9C7C7",
                    padding: "0.45rem 0.4375rem 0.2rem 1rem",
                    color: "#000000",
                },
            }),
            ...(paymentType?.value?.type === appConstant.PAYMENT_METHOD.US_BANK && {
                ".Input": {
                    lineHeight: "25px",
                    fontSize: "16px",
                    border: "1px solid #C9C7C7",
                    color: "#000000",
                },
            }),
            ".Input:focus": {
                border: "1px solid #515151",
                borderColor: "#515151",
                boxShadow: "0px 7px 14px rgba(20, 34, 117, 0.15)",
            },
            ".Input::placeholder": {
                color: "#515151",
                fontSize: "16px",
            },
            ".Input--invalid": {
                border: "1px solid #E51E42",
                borderColor: "#E51E42",
            },
            ".Tab": {
                borderRadius: "16px",
                border: "1px solid #C9C7C7",
                color: "#C9C7C7",
                fontSize: "16px",
                fontWeight: "700",
                boxShadow: "none",
                padding: "22px 22px",
                marginRight: "6px",
                marginLeft: "6px",
            },
            ".Tab--selected": {
                border: "1px solid",
                borderColor: "#515151",
                color: "#515151",
                fontSize: "16px",
                fontWeight: "700",
                boxShadow: "none",
            },
            ".Tab--selected:focus": {
                borderColor: "#515151",
                color: "#515151",
                boxShadow: "none",
            },
            ".Tab--selected:hover": {
                borderColor: "#515151",
                color: "#515151",
                boxShadow: "none",
            },
            ".TabIcon": {
                color: "#C9C7C7",
                fill: "#C9C7C7",
            },
            ".TabIcon:hover": {
                color: "#C9C7C7",
                fill: "#C9C7C7",
            },
            ".TabIcon:focus": {
                color: "#C9C7C7",
                fill: "#C9C7C7",
            },
            ".TabIcon--selected": {
                color: "#515151",
                fill: "#515151",
            },
            ".TabIcon--selected:hover": {
                color: "#515151",
                fill: "#515151",
            },
            ".TabLabel": {
                paddingTop: "12px",
                fontSize: "16px",
                fontWeight: "700",
            },
        },
        labels: "floating",
    };

    const stripePromise = useMemo(
        () =>
            setupIntent
                .invoke()
                .then((resp) => {
                    const { payload } = resp;

                    const { publicKey, secret } = get(payload, "payload.response.keys", {});

                    setPaymentKeys({ publicKey, secret });

                    logger.info("setup intent API call successfull");

                    return loadStripe(publicKey);
                })
                .catch((error) => {
                    if (error?.error?.code === 401) {
                        logger.error("session expired in setup intent API call", error);

                        setTimeout(() => {
                            sendPostMessage("salesFunnel-sdk-session-expired");
                        }, 100);
                    } else {
                        logger.error("Error in setup intent API call", error);

                        history.push(ROUTE_CONSTANTS.ERROR);
                    }

                    return loadStripe(null);
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authorizationSetUpIntent]
    );

    return (
        <>
            {paymentKeys.publicKey && stripePromise && (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret: paymentKeys.secret,
                        appearance,
                    }}
                >
                    <PaymentCardDetails
                        prospectId={prospectId}
                        setError={setError}
                        isError={isError}
                        isApiError={isApiError}
                        setIsApiError={setIsApiError}
                        refreshComponent={refreshComponent}
                        paymentType={paymentType}
                        changePaymentType={changePaymentType}
                    />
                </Elements>
            )}
        </>
    );
};

export default PaymentDetails;

PaymentDetails.propTypes = {
    setPaymentKeys: PropTypes.func,
    paymentKeys: PropTypes.object,
    authorizationSetUpIntent: PropTypes.string,
    countryCode: PropTypes.string,
    prospectId: PropTypes.string,
    setError: PropTypes.func,
    isError: PropTypes.bool,
    setIsApiError: PropTypes.func,
    isApiError: PropTypes.bool,
    refreshComponent: PropTypes.func,
    paymentType: PropTypes.object,
    changePaymentType: PropTypes.func,
};
